@import "./assets/css/colors.scss";

.bg-black {
  background-color: $black !important;
}

.bg-dark {
  background-color: $dark !important;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-light {
  background-color: $light !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-green {
  background-color: $green !important;
}

.bg-error {
  background-color: $error !important;
}

.bg-yellow {
  background-color: $yellow !important;
}

.bg-purple-dark {
  background-color: $purple-dark !important;
}

.bg-purple-darker {
  background-color: $purple-darker !important;
}

.txt-black {
  color: $black;
}

.txt-dark {
  color: $dark;
}

.txt-gray {
  color: $gray;
}

.txt-light {
  color: $light;
}

.txt-white {
  color: $white;
}

.txt-purple {
  color: $purple;
}

.txt-green {
  color: $green;
}

.txt-error {
  color: $error;
}

.purple-faded {
  color: $purple-faded;
}

.green-faded {
  color: $green-faded;
}

.black-faded {
  color: $black-faded;
}

.light-faded {
  color: $light-faded;
}

html {
  font-family: 'Avenir Next', sans-serif;
  font-weight: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%
}

span, a, .minuscule {
  font-family: 'Roboto', sans-serif;
}

h1 {
  text-transform: uppercase;
  margin-top: 0;
}

h1 span {
  font-family: 'Avenir Next', sans-serif;
}

.avenir {
  font-family: 'Avenir Next', sans-serif !important;
}

a {
  text-decoration: none;
}

.stats, .pager {
  width: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-center {
  text-align: center;
}

.icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-arrow {
  margin-left: 2rem;
  margin-right: 2rem;
}

.icon-arrow-left-dark {
  background: url("./assets/icons/chevron-left-solid.svg") center no-repeat;
}

.icon-arrow-left-dark:hover {
  background: url("./assets/icons/chevron-left-solid-dark.svg") center no-repeat;
}

.icon-arrow-right-dark {
  background: url("./assets/icons/chevron-right-solid.svg") center no-repeat;
}

.icon-arrow-right-dark:hover {
  background: url("./assets/icons/chevron-right-solid-dark.svg") center no-repeat;
}

.icon-arrow-left-white {
  background: url("./assets/icons/white/chevron-left-solid-dark.svg") center no-repeat;
}

.icon-arrow-left-white:hover {
  background: url("./assets/icons/white/chevron-left-solid.svg") center no-repeat;
}

.icon-arrow-right-white {
  background: url("./assets/icons/white/chevron-right-solid-dark.svg") center no-repeat;
}

.icon-arrow-right-white:hover {
  background: url("./assets/icons/white/chevron-right-solid.svg") center no-repeat;
}

.tap-area {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.tap-area div {
  height: 100%;
  width: 50%;
}

.content-wrapper {
  display: flex;
  align-items: center;
  //height: 100%;
}

.content {
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.stats-numbers {
  justify-content: center;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  //max-height: 120px;
}

.pager-content {
  display: flex;
  justify-content: flex-end;
}

.mt-0 {
  margin-top: 0!important;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.o25 {
  opacity: 0.25;
}

.o5 {
  opacity: 0.5;
}

.stats-numbers p {
  margin: 0;
}

.stats-numbers p:first-child {
  opacity: 0.25;
}

.stats-numbers p:last-child {
  opacity: 0.25;
}

.stats-numbers p:nth-child(2) {
  opacity: 0.5;
}

.stats-numbers p:nth-child(4) {
  opacity: 0.5;
}

.btn {
  background: linear-gradient(90deg, $purple 0%, $green 100%);
  border-radius: 60px;
  text-transform: uppercase;
  color: $white;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
  border: none;
  font-family: inherit;
  font-size: inherit;
  -webkit-transition: background 1s ease-out;
  -moz-transition: background 1s ease-out;
  -o-transition: background 1s ease-out;
  transition: background 1s ease-out;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.points {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stats-number-wrapper {
  display: flex;
  align-items: center;
}

.pager-content-date {
  text-transform: uppercase;
}

.video-content {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  vertical-align: top;
}

.container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.fade-right-enter {
  transform: translateX(100%);
}
.fade-right-enter-active {
  transform: translateX(0%);
}
.fade-right-exit {
  position: absolute;
  top: 0;
  transform: translateX(0%);
}
.fade-right-exit-active {
  position: absolute;
  top: 0;
  transform: translateX(-100%);
}
.fade-right-enter-active,
.fade-right-exit-active {
  transition: transform 1000ms;
}

.fade-left-enter {
  transform: translateX(-100%);
}
.fade-left-enter-active {
  transform: translateX(0%);
}
.fade-left-exit {
  position: absolute;
  top: 0;
  transform: translateX(0%);
}
.fade-left-exit-active {
  position: absolute;
  top: 0;
  transform: translateX(100%);
}
.fade-left-enter-active,
.fade-left-exit-active {
  transition: transform 1000ms;
}

@media only screen and (min-width: 384px) {
  .story-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 22rem);
  }
}

// SM
@media only screen and (max-width: 383px) {
  html {
    font-size: 12px;
  }

  h1, h1 span {
    font-size: 2.5rem;
    line-height: 33px;
    //margin-top: 12px;
    //margin-bottom: 6px;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 20px;
  }

  h3 {
    font-size: 1.333rem;
    line-height: 19px;
  }

  body {
    font-size: 1.333rem;
    line-height: 19px;
    height: calc(var(--height, 1vh) * 100);
  }

  span {
    font-size: 1.167rem;
  }

  .minuscule {
    font-size: 1.083rem;
  }

  .points {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .stats, .pager {
    height: 5rem;
  }

  .content {
    //width: 100%;
    padding-top: 2rem;
    //padding-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .content-wrapper {
    width: 100%;
  }

  .btn {
    padding: 15px 36px;
    margin-top: 24px;
  }

  .logo {
    width: 36px;
    height: 48px;
  }

  .icon {
    width: 36px;
    height: 36px;
  }

  .wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .stats {
    padding-left: 3rem;
    padding-right: 3rem;
    box-sizing: border-box;
    margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .stats-helper, .icon {
    //flex-basis: 25%;
  }

  .stats-content {
    flex-basis: 50%;
    flex-direction: column;
  }

  .stats-numbers p:first-child, .stats-numbers p:last-child,
  .stats-numbers p:nth-child(2), .stats-numbers p:nth-child(4) {
    display: none;
  }

  .pager {
    margin-top: auto;
    margin-bottom: 1rem;
  }

  .pager-content-date {
    margin-left: 1rem;
    text-align: left;
  }

  .pager-stats {
    margin-top: initial;
  }

}

// LM
@media only screen and (min-width: 384px) and (max-width: 599px) {
  html {
    font-size: 12px;
  }

  h1, h1 span {
    font-size: 3rem;
    line-height: 40px;
    //margin-top: 24px;
    //margin-bottom: 12px;
  }

  h2 {
    font-size: 1.667rem;
    line-height: 22px;
  }

  h3 {
    font-size: 1.333rem;
    line-height: 19px;
  }

  body {
    font-size: 1.5rem;
    line-height: 22px;
    height: calc(var(--height, 1vh) * 100);
  }

  span {
    font-size: 1.25rem;
  }

  .minuscule {
    font-size: 1.125rem;
  }

  .stats {
    height: 10rem;
  }

  .content {
    width: 26rem;
    padding-top: 3rem;
    //padding-bottom: 3rem;
  }

  .icon-arrow {
    margin: 0;
  }

  .pager {
    height: 11rem;
  }

  .pager-content {
    flex-direction: column;
    align-items: center;
  }

  .pager-content-date  {
    text-align: center;
  }

  .points-white {
    background: url("./assets/images/points/lmpt/white.svg") no-repeat center;
  }

  .points-black {
    background: url("./assets/images/points/lmpt/black.svg") no-repeat center;
  }

  .points {
    background-position-y: bottom;
  }

  .btn {
    padding: 15px 36px;
    margin-top: 36px;
  }

  .logo {
    width: 36px;
    height: 48px;
  }

  .icon {
    width: 36px;
    height: 36px;
  }

  .wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .stats {
    //margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .stats-helper, .icon {
    //flex-basis: 25%;
  }

  .stats-content {
    flex-basis: 50%;
    flex-direction: column;
  }

  .pager {
    margin-top: auto;
    //margin-bottom: 1rem;
  }

  .pager-stats {
    margin-top: initial;
  }

  .stats-numbers {
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .stats-numbers p{
    //margin-right: .5rem;
    //margin-left: .5rem;
    min-width: 26px;
    text-align: center;
  }

  .content-wrapper {
    align-self: flex-start;
  }
}

// PT
@media only screen and (min-width: 600px) and (max-width: 899px) {
  html {
    font-size: 13px;
  }

  h1, h1 span {
    font-size: 3.231rem;
    line-height: 46px;
    //margin-top: 26px;
    //margin-bottom: 13px;
  }

  h2 {
    font-size: 1.615rem;
    line-height: 23px;
  }

  h3 {
    font-size: 1.308rem;
    line-height: 20px;
  }

  body {
    font-size: 1.308rem;
    line-height: 20px;
  }

  span {
    font-size: 1.154rem;
  }

  .minuscule {
    font-size: 1.231rem;
  }

  .stats {
    height: 9rem;
  }

  .content {
    width: 36rem;
    padding-top: 4rem;
    //padding-bottom: 4rem;
  }

  .pager {
    height: 11rem;
    margin-top: auto;
  }

  .pager-content {
    flex-direction: column;
    align-items: center;
  }

  .pager-content-date  {
    text-align: center;
  }

  .points-white {
    background: url("./assets/images/points/lmpt/white.svg") no-repeat center;
  }

  .points-black {
    background: url("./assets/images/points/lmpt/black.svg") no-repeat center;
  }

  .points {
    background-position-y: bottom;
  }

  .btn {
    padding: 16px 39px;
    margin-top: 39px;
  }

  .logo {
    width: 39px;
    height: 52px;
  }

  .icon {
    width: 39px;
    height: 39px;
  }

  .wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .stats {
    //margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .stats-helper, .icon {
    //flex-basis: 25%;
  }

  .stats-content {
    flex-basis: 50%;
    flex-direction: column;
  }

  .stats-numbers {
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .stats-numbers p{
    //margin-right: .5rem;
    //margin-left: .5rem;
    min-width: 26px;
    text-align: center;
  }

  .content-wrapper {
    align-self: flex-start;
  }

}

// LT
@media only screen and (min-width: 900px) and (max-width: 1279px) {
  html {
    font-size: 13px;
  }

  h1, h1 span {
    font-size: 3.538rem;
    line-height: 51px;
    //margin-top: 26px;
    //margin-bottom: 13px;
  }

  h2 {
    font-size: 1.769rem;
    line-height: 25px;
  }

  h3 {
    font-size: 1.538rem;
    line-height: 24px;
  }

  body {
    font-size: 1.308rem;
    line-height: 20px;
  }

  span {
    font-size: 1.154rem;
  }

  .minuscule {
    font-size: 1.231rem;
  }

  .stats, .pager {
    width: 12rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .points-white {
    background: url("./assets/images/points/lt/white.svg") no-repeat center;
  }

  .points-black {
    background: url("./assets/images/points/lt/black.svg") no-repeat center;
  }

  .points {
    background-position-x: left;
  }

  .content {
    width: 38rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .btn {
    padding: 16px 39px;
    margin-top: 39px;
  }

  .logo {
    width: 39px;
    height: 52px;
  }

  .icon, .stats-helper {
    width: 39px;
    height: 39px;
  }

  .stats-numbers {
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .stats-numbers p{
    //margin-right: .5rem;
    //margin-left: .5rem;
    min-width: 26px;
    text-align: center;
  }

  .stats-numbers p:first-child, .stats-numbers p:last-child,
  .stats-numbers p:nth-child(2), .stats-numbers p:nth-child(4) {
    display: none;
  }

  .stats-content {
    flex-direction: column;
  }

  .pager-content {
    width: 100%;
    //margin-right: 9rem;
  }

  .pager-content-date  {
    margin-left: 1rem;
  }

  .subtitle {
    width: 50%;
  }

  .story-holder{
    height: initial;
  }
}

// SD
@media only screen and (min-width: 1280px) and (max-width: 1799px) {
  html {
    font-size: 14px;
  }

  h1, h1 span {
    font-size: 4rem;
    line-height: 62px;
    //margin-top: 28px;
    //margin-bottom: 14px;
  }

  h2 {
    font-size: 1.786rem;
    line-height: 25px;
  }

  h3 {
    font-size: 1.357rem;
    line-height: 23px;
  }

  body {
    font-size: 1.286rem;
    line-height: 22px;
  }

  span {
    font-size: 1.286rem;
  }

  .minuscule {
    font-size: 1.143rem;
  }

  .stats, .pager {
    width: 20rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .points-white {
    background: url("./assets/images/points/sd/white.svg") no-repeat left;
  }

  .points-black {
    background: url("./assets/images/points/sd/black.svg") no-repeat left;
  }

  .content {
    width: 40rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .btn {
    padding: 18px 42px;
    margin-top: 60px;
  }

  .logo {
    width: 42px;
    //height: 56px;
  }

  .icon, .stats-helper {
    width: 42px;
    height: 42px;
  }

  .stats-numbers {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .pager-content {
    // width: 100%;
    //margin-right: 9rem;
  }

  .pager-content-date {
    margin-left: 1rem;
  }

  .subtitle {
    width: 50%;
  }

  .story-holder{
    height: initial;
    width: calc(100% - 40rem);
  }

  .icon-arrow {
    margin: 0;
  }
}

// LD
@media only screen and (min-width: 1800px) {
  html {
    font-size: 16px;
  }

  h1, h1 span {
    font-size: 4rem;
    line-height: 70px;
    //margin-top: 32px;
    //margin-bottom: 16px;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 31px;
  }

  h3 {
    font-size: 1.313rem;
    line-height: 25px;
  }

  body {
    font-size: 1.325rem;
    line-height: 24px;
  }

  span {
    font-size: 1.25rem;
  }

  .minuscule {
    font-size: 1.125rem;
  }

  .stats, .pager {
    width: 24rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .points-white {
    background: url("./assets/images/points/ld/white.svg") no-repeat left;
  }

  .points-black {
    background: url("./assets/images/points/ld/black.svg") no-repeat left;
  }

  .content {
    width: 40rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .btn {
    padding: 21px 48px;
    margin-top: 48px;
  }

  .logo {
    width: 48px;
    //height: 64px;
  }

  .icon, .stats-helper {
    width: 42px;
    height: 42px;
  }

  .stats-numbers {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .pager-content-date {
    margin-left: 1rem;
  }

  .subtitle {
    width: 50%;
  }

  .story-holder{
    height: initial;
    width: calc(100% - 48rem);
  }

  .icon-arrow {
    margin: 0;
  }
}

.story19-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
}

.story19-content > img {
  height: 80px;
  margin-bottom: 5rem;
  margin-top: 5rem;
}

@keyframes zoomOut {
  0% {
    transform: scale(6);
    padding-bottom: 3rem;
  }
  100% {
    transform: scale(1);
    padding-bottom: 0;
  }
}

.zoom-animation {
  animation: 3s ease-in 0s 1 zoomOut;
}

@keyframes cardFlip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.flip-animation {
  animation: 1s ease-in 4s 1 cardFlip;
}

.story19-content-last {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 900px) {
  .story19-content {
    max-width: 550px;
  }
}


.story19-content-last > div {
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  display: inline-block;
  height: 0;
  padding-bottom: 33.3%;
  width: 33.3%;

}

.card-front > img {
  height: 25%;
}

.triangle-up-left {
  width: 0;
  height: 0;
  padding-bottom: 100%;
  padding-left: 100%;
  overflow: hidden;
}
.triangle-up-left:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: -500px;
  border-bottom: 500px solid transparent;
  border-left: 500px solid $dark-blue;
}

.flip-card {
  -webkit-transition: transform 0.6s;
  transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -o-transition: transform 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.flip-card {
  &:hover, &:active {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
  }
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.card-back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.odometer-digit, .odometer-digit > span, .odometer-value {
  font-family: 'Avenir Next', sans-serif !important;
}

@media (-webkit-device-pixel-ratio: 1.5) {
  .story19-content.zoom-animation {
    zoom: 0.6;
  }
}
