.stats-helper {
  display: none;
}

.space-around {
  justify-content: space-around;
}

.opacity-0 {
  opacity: 0!important;
}

// LM
@media only screen and (min-width: 384px) and (max-width: 599px) {
  .stats {
    width: 26rem;
  }
}

// PT
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .stats {
    width: 36rem;
  }
}

@media only screen and (min-width: 900px) {
  .stats-helper {
    display: initial;
  }
}