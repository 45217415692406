.story-stats-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.story-stats-info {
  flex-direction: column;
}

.content-enter {
  opacity: 0;
  transform: scale(0.9);
}
.content-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: 1.5s ease-out 0s 1 fadeIn;
}

.card-container {
  margin-top: 2rem;
}

.card {
  background: #FFFFFF;
  padding: 18px 24px;
  box-shadow: 0 4px 4px rgba(23, 25, 46, 0.1);
  border-radius: 12px;
  margin-bottom: 1rem;
}

.card:last-child {
  margin-bottom: 0;
}

.card h2 {
  margin: 0;
}

.swiper-container {
  padding-bottom: 4rem !important;
}

@media only screen and (max-width: 599px) {
  .card img {
    height: 30px;
    width: 30px;
    margin-right: 23px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1279px) {
  .card img {
    height: 32.5px;
    width: 32.5px;
    margin-right: 29px;
  }
}

// SM
@media only screen and (max-width: 383px) {
  .story-stats-icon {
    width: 36px;
    height: 36px;
    margin-left: 1rem;
    margin-right: 2rem;
  }

  .story-stats-info h1 {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .wrapper-stats {
    align-items: initial;
    justify-content: space-between;
  }
}

// LM
@media only screen and (min-width: 384px) and (max-width: 599px) {
  .story-stats-icon {
    width: 36px;
    height: 36px;
    margin-left: 1rem;
    margin-right: 3rem;
  }

  .story-stats-info h1 {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .card-container, .swiper-container {
    width: 26rem;
  }
}

// PT
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .story-stats-icon {
    width: 39px;
    height: 39px;
    margin-left: 39px;
    margin-right: 39px;
  }

  .story-stats-info h1 {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .card-container, .swiper-container {
    width: 36rem;
  }
}

// LT
@media only screen and (min-width: 900px) and (max-width: 1279px) {
  .story-stats-icon {
    width: 39px;
    height: 39px;
    margin-left: 39px;
    margin-right: 39px;
  }

  .story-stats-info h1 {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .card-container, .swiper-container {
    width: 38rem;
  }
}

// SD
@media only screen and (min-width: 1280px) and (max-width: 1799px) {
  .story-stats-icon {
    width: 42px;
    height: 42px;
    margin-left: 42px;
    margin-right: 42px;
  }

  .story-stats-info h1 {
    margin-bottom: 7px;
    margin-top: 7px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .card img {
    height: 35px;
    width: 35px;
    margin-right: 30px;
  }

  .card-container, .swiper-container {
    width: 40rem;
  }
}

// LD
@media only screen and (min-width: 1800px) {
  .story-stats-icon {
    width: 42px;
    height: 42px;
    margin-left: 42px;
    margin-right: 42px;
  }

  .story-stats-info h1 {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .story-stats-info > h1:first-of-type {
    margin-top: 0;
  }

  .story-stats-info > h1:last-of-type {
    margin-bottom: 0;
  }

  .card img {
    height: 40px;
    width: 40px;
    margin-right: 35px;
  }

  .card-container, .swiper-container {
    width: 40rem;
  }

}

.bordered {
  content: '';
  display: inline-block;
  height: 1px;
  left: -50px;
  background: #fff;
  width: 100%;
  opacity: 0.1;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wrapper-stats {
  justify-content: space-between;
}
