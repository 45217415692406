.wrapper-share {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.share-restart {
  cursor: pointer;
  margin-top: 5rem;
  transition: 0.2s;
}

.share-restart:hover {
  transition: 0.2s;
  transform: scale(1.15);
}

.pager-share {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.content-share {
  margin: initial;
}

.share-logo-holder {
  display: flex;
  justify-content: space-around;
}

.share-logo-holder > a {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgb(23 25 46 / 10%);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.2s;
  top: 0;
  cursor: pointer;
  width: 30%;
  height: 100%;
}

.share-logo-holder > a:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

.pointer {
  cursor: pointer;
}

// SM
@media only screen and (max-width: 383px) {
  .share-logo-holder {
    width: 248px;
    height: 60px;
  }
}

// LM
@media only screen and (min-width: 384px) and (max-width: 599px) {
  .share-logo-holder {
    width: 312px;
    height: 60px;
  }
}

// PT
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .share-logo-holder {
    width: 465px;
    height: 65px;
  }
}

// LT
@media only screen and (min-width: 900px) and (max-width: 1279px) {
  .share-logo-holder {
    width: 495px;
    height: 65px;
  }
}

// SD
@media only screen and (min-width: 1280px) and (max-width: 1799px) {
  .share-logo-holder {
    width: 560px;
    height: 70px;
  }

  .share-parkl-logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

// LD
@media only screen and (min-width: 1800px) {
  .share-logo-holder {
    width: 640px;
    height: 80px;
  }
}