@import '../../assets/css/colors.scss';

.overflow-hidden {
  overflow: hidden;
}

.bg {
  background: url(../../assets/images/parkl5years_zsolt.png) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.lang {
  color: $white;
  cursor: pointer;
  //position: absolute;
  //bottom: 0;
  margin-top: 4rem;
  text-transform: uppercase;
}

.lang:hover {
  color: $green;
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .content-main {
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding-bottom: 6rem;
  }

  .header-main {
    margin-top: 26px;
    margin-bottom: 13px;
  }

  .bg {
    background: url(../../assets/images/parkl5years_zsolt_3.png) no-repeat top;
  }
}

@media only screen and (max-width: 599px) {
  .content-main {
    padding-bottom: 3rem;
  }

  .header-main {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 383px) {
  .content-main {
    padding-bottom: 1rem;
  }

  .header-main {
    margin-top: 12px;
    margin-bottom: 6px;
  }

  .bg {
    background: url(../../assets/images/parkl5years_zsolt_sm.png) no-repeat top;
  }
}

@media only screen and (max-width: 1279px) {
  .header-main {
    margin-top: 26px;
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 1799px) {
  .header-main {
    margin-top: 28px;
    margin-bottom: 14px;
  }
}

@media only screen and (min-width: 1800px) {
  .header-main {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}