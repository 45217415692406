/* colors */
$black: #17192e;
$dark-blue: hsla(235, 33%, 14%, 1);
$dark: hsl(237, 22%, 22%);
$gray: hsl(0, 0%, 77%);
$light: hsl(228, 33%, 94%);
$white: hsl(0, 0%, 100%);
$purple: hsl(257, 83%, 60%);
$green: hsl(168, 96%, 48%);
$error: hsl(350, 100%, 65%);
$purple-faded: hsla(257, 83%, 60%, 0.5);
$green-faded: hsl(168, 96%, 48%, 0.5);
$black-faded: hsl(235, 33%, 14%, 0.5);
$light-faded: hsl(228, 33%, 94%, 0.5);
$yellow: hsla(39, 100%, 50%, 1);
$purple-dark: hsla(261, 53%, 28%, 1);
$purple-darker: hsla(263, 52%, 22%, 1);